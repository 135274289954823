<template>
  <div>
    <h1>Thanks!</h1>
    <p>
      Thanks for your interest in sodisp. We will get back to you ASAP.
    </p>
    <p>
      In the mean time, feel free to explore the features of our platform:
    </p>
    <p>
      <v-btn outlined color="primary" to="/features">Features</v-btn>
      <v-btn outlined color="primary" to="/about">More about us</v-btn>
    </p>
  </div>
</template>


<script>

export default {
  name: "ThankYou",
  props: {
  },
  data: function() {
    return {
    };
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<style scoped>
</style>